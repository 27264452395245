// =========================================================================================
//   File Name: custom-options.scss
//   Description: custom checkbox and radion button style.
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

// Component: custom options
// ========================================================================

.custom-options-checkable {
  .custom-option-item {
    width: 100%;
    cursor: pointer;
    border-radius: 0.42rem;
    color: $secondary;
    background-color: $custom-options-bg-color;
    border: 1px solid $border-color;
    .custom-option-item-title {
      color: $secondary;
    }
  }
}

.custom-option-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);

  &:checked {
    + .custom-option-item {
      color: $primary;
      background-color: rgba($primary, 0.12);
      border-color: $primary;
      .custom-option-item-title {
        color: $primary;
      }
    }
  }
}

.container-small{
  max-width: 800px;
  margin: 0 auto;
}

.bg-loginImage{
  background-image: url('../../../../assets/images/jpg/tifi-entregas.jpg');
  background-size: 120% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

//Limitar cantidad de caracteres a mostrar

.limit-text{
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: 2; /* number of lines to show */ 
  -webkit-box-orient: vertical;
}

.text-observacion{
  height: 15vh;
  resize: none;
}

.bg-secondary-alt{
  background-color: #3af9ca;
}
